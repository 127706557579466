interface KantanLogoProps {
  color?: string;
  width?: string;
  height?: string;
}

const Small = ({
  color = 'white',
  width = '33',
  height = '34',
}: KantanLogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6259 1.64658C16.4255 1.18095 17.4139 1.18174 18.2127 1.64866L29.5328 8.26517C30.3919 8.76726 30.9199 9.68752 30.9199 10.6825L30.9199 22.2815C30.9199 23.3124 30.0842 24.1481 29.0533 24.1481L24.3866 24.1646C23.3557 24.1646 22.5199 23.3124 22.5199 22.2815L22.5199 15.7414C22.5199 15.1579 22.2086 14.6187 21.7032 14.3269L17.8532 12.1042C17.2757 11.7708 16.5641 11.7708 15.9866 12.1042L12.1366 14.3269C11.6312 14.6186 11.3199 15.1579 11.3199 15.7414L11.3199 22.2815C11.3199 23.3124 10.4842 24.1481 9.45327 24.1481H4.78662C3.75568 24.1481 2.91995 23.3124 2.91995 22.2815L2.91992 10.6553C2.91992 9.65866 3.44966 8.73716 4.31089 8.23564L15.6259 1.64658Z"
        fill={color}
      />
      <path
        d="M18.2929 17.0054C18.2929 16.2966 18.8674 15.7221 19.5762 15.7221C20.285 15.7221 20.8595 16.2966 20.8595 17.0054C20.8595 17.7141 20.285 18.2887 19.5762 18.2887C18.8674 18.2887 18.2929 17.7141 18.2929 17.0054Z"
        fill={color}
      />
      <path
        d="M4.91352 30.277L4.07332 31.2841V33.0601H2.66992V26.67H4.07332V29.7094H4.12872L5.04278 28.4735L6.49234 26.67H8.0804L5.91067 29.2974L8.27429 33.0601H6.6216L4.91352 30.277Z"
        fill={color}
      />
      <path
        d="M12.1477 33.0601C11.9015 33.0601 11.6953 32.9838 11.5291 32.8313C11.3691 32.6787 11.2675 32.4742 11.2244 32.2179H11.169C11.0952 32.5291 10.932 32.7672 10.6797 32.932C10.4273 33.0906 10.1165 33.17 9.74715 33.17C9.26089 33.17 8.88849 33.0418 8.62997 32.7855C8.37145 32.5291 8.24219 32.1904 8.24219 31.7693C8.24219 31.2627 8.42685 30.8874 8.79617 30.6432C9.17164 30.393 9.67945 30.2679 10.3196 30.2679H11.0859V29.9658C11.0859 29.7338 11.0244 29.5507 10.9013 29.4165C10.7782 29.2761 10.572 29.2059 10.2827 29.2059C10.0118 29.2059 9.7964 29.2639 9.63636 29.3798C9.47632 29.4958 9.34398 29.627 9.23935 29.7735L8.42685 29.0594C8.62382 28.7726 8.87003 28.5498 9.16548 28.3911C9.46709 28.2263 9.87334 28.1439 10.3842 28.1439C11.0736 28.1439 11.5907 28.2935 11.9354 28.5925C12.28 28.8916 12.4524 29.328 12.4524 29.9017V32.0073H12.9048V33.0601H12.1477ZM10.255 32.227C10.4827 32.227 10.6766 32.1782 10.8366 32.0805C11.0028 31.9829 11.0859 31.8242 11.0859 31.6045V31.0369H10.4212C9.88565 31.0369 9.61789 31.2169 9.61789 31.577V31.7144C9.61789 31.8913 9.67329 32.0226 9.78409 32.108C9.89488 32.1874 10.0518 32.227 10.255 32.227Z"
        fill={color}
      />
      <path
        d="M13.4526 33.0601V28.2538H14.8191V29.0686H14.8745C14.9607 28.8183 15.1115 28.6017 15.3269 28.4186C15.5424 28.2355 15.8409 28.1439 16.2225 28.1439C16.7211 28.1439 17.0966 28.3087 17.3489 28.6383C17.6075 28.9679 17.7367 29.4378 17.7367 30.0482V33.0601H16.3702V30.158C16.3702 29.8467 16.321 29.6179 16.2225 29.4714C16.124 29.3188 15.9486 29.2425 15.6962 29.2425C15.5854 29.2425 15.4777 29.2578 15.3731 29.2883C15.2685 29.3127 15.173 29.3554 15.0869 29.4165C15.0069 29.4775 14.9422 29.5538 14.893 29.6453C14.8437 29.7308 14.8191 29.8315 14.8191 29.9474V33.0601H13.4526Z"
        fill={color}
      />
      <path
        d="M20.2823 33.0601C19.8083 33.0601 19.4482 32.9411 19.202 32.7031C18.9619 32.4651 18.8419 32.1172 18.8419 31.6594V29.3066H18.1587V28.2538H18.5003C18.685 28.2538 18.8111 28.2111 18.8789 28.1256C18.9466 28.0341 18.9804 27.9059 18.9804 27.7411V26.9538H20.2084V28.2538H21.1686V29.3066H20.2084V32.0073H21.0947V33.0601H20.2823Z"
        fill={color}
      />
      <path
        d="M25.3309 33.0601C25.0847 33.0601 24.8785 32.9838 24.7123 32.8313C24.5523 32.6787 24.4507 32.4742 24.4076 32.2179H24.3522C24.2784 32.5291 24.1152 32.7672 23.8629 32.932C23.6105 33.0906 23.2997 33.17 22.9304 33.17C22.4441 33.17 22.0717 33.0418 21.8132 32.7855C21.5547 32.5291 21.4254 32.1904 21.4254 31.7693C21.4254 31.2627 21.6101 30.8874 21.9794 30.6432C22.3548 30.393 22.8627 30.2679 23.5028 30.2679H24.2691V29.9658C24.2691 29.7338 24.2076 29.5507 24.0845 29.4165C23.9614 29.2761 23.7552 29.2059 23.4659 29.2059C23.195 29.2059 22.9796 29.2639 22.8196 29.3798C22.6595 29.4958 22.5272 29.627 22.4226 29.7735L21.6101 29.0594C21.807 28.7726 22.0532 28.5498 22.3487 28.3911C22.6503 28.2263 23.0565 28.1439 23.5674 28.1439C24.2568 28.1439 24.7739 28.2935 25.1186 28.5925C25.4633 28.8916 25.6356 29.328 25.6356 29.9017V32.0073H26.088V33.0601H25.3309ZM23.4382 32.227C23.6659 32.227 23.8598 32.1782 24.0198 32.0805C24.186 31.9829 24.2691 31.8242 24.2691 31.6045V31.0369H23.6044C23.0689 31.0369 22.8011 31.2169 22.8011 31.577V31.7144C22.8011 31.8913 22.8565 32.0226 22.9673 32.108C23.0781 32.1874 23.235 32.227 23.4382 32.227Z"
        fill={color}
      />
      <path
        d="M26.6359 33.0601V28.2538H28.0023V29.0686H28.0577C28.1439 28.8183 28.2947 28.6017 28.5101 28.4186C28.7256 28.2355 29.0241 28.1439 29.4057 28.1439C29.9043 28.1439 30.2798 28.3087 30.5321 28.6383C30.7907 28.9679 30.9199 29.4378 30.9199 30.0482V33.0601H29.5535V30.158C29.5535 29.8467 29.5042 29.6179 29.4057 29.4714C29.3072 29.3188 29.1318 29.2425 28.8794 29.2425C28.7687 29.2425 28.6609 29.2578 28.5563 29.2883C28.4517 29.3127 28.3563 29.3554 28.2701 29.4165C28.1901 29.4775 28.1254 29.5538 28.0762 29.6453C28.0269 29.7308 28.0023 29.8315 28.0023 29.9474V33.0601H26.6359Z"
        fill={color}
      />
    </svg>
  );
};

const Full = ({
  color = '#FFFFFF',
  width = '48',
  height = '48',
}: KantanLogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 213 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.563 0.731893C28.2347 -0.245527 30.3009 -0.243856 31.971 0.736269L55.6367 14.6252C57.4326 15.6791 58.5365 17.6109 58.5365 19.6995L58.5366 44.0472C58.5366 46.2113 56.7894 47.9656 54.6341 47.9656L44.8781 48C42.7228 48 40.9756 46.2113 40.9756 44.0472L40.9756 30.3188C40.9756 29.0938 40.3247 27.9619 39.2682 27.3495L31.2194 22.6839C30.012 21.984 28.5245 21.984 27.3172 22.6839L19.2684 27.3495C18.2118 27.9619 17.5609 29.0938 17.5609 30.3188L17.561 44.0472C17.561 46.2112 15.8138 47.9656 13.6586 47.9656H3.90249C1.74724 47.9656 5.95464e-05 46.2112 5.95464e-05 44.0472L0 19.6423C0 17.5503 1.10747 15.6159 2.90796 14.5632L26.563 0.731893Z"
        fill={color}
      />
      <path
        d="M32.1385 32.972C32.1385 31.4842 33.3397 30.2781 34.8215 30.2781C36.3032 30.2781 37.5044 31.4842 37.5044 32.972C37.5044 34.4598 36.3032 35.6659 34.8215 35.6659C33.3397 35.6659 32.1385 34.4598 32.1385 32.972Z"
        fill={color}
      />
      <path
        d="M84.8001 28.84L81.1601 33.24V41H75.0801V13.08H81.1601V26.36H81.4001L85.3601 20.96L91.6401 13.08H98.5201L89.1201 24.56L99.3601 41H92.2001L84.8001 28.84Z"
        fill={color}
      />
      <path
        d="M116.141 41C115.074 41 114.181 40.6667 113.461 40C112.768 39.3334 112.328 38.44 112.141 37.32H111.901C111.581 38.68 110.874 39.72 109.781 40.44C108.688 41.1334 107.341 41.48 105.741 41.48C103.634 41.48 102.021 40.92 100.901 39.8C99.781 38.68 99.221 37.2 99.221 35.36C99.221 33.1467 100.021 31.5067 101.621 30.44C103.248 29.3467 105.448 28.8 108.221 28.8H111.541V27.48C111.541 26.4667 111.274 25.6667 110.741 25.08C110.208 24.4667 109.314 24.16 108.061 24.16C106.888 24.16 105.954 24.4134 105.261 24.92C104.568 25.4267 103.994 26 103.541 26.64L100.021 23.52C100.874 22.2667 101.941 21.2934 103.221 20.6C104.528 19.88 106.288 19.52 108.501 19.52C111.488 19.52 113.728 20.1734 115.221 21.48C116.714 22.7867 117.461 24.6933 117.461 27.2V36.4H119.421V41H116.141ZM107.941 37.36C108.928 37.36 109.768 37.1467 110.461 36.72C111.181 36.2934 111.541 35.6 111.541 34.64V32.16H108.661C106.341 32.16 105.181 32.9467 105.181 34.52V35.12C105.181 35.8934 105.421 36.4667 105.901 36.84C106.381 37.1867 107.061 37.36 107.941 37.36Z"
        fill={color}
      />
      <path
        d="M121.794 41V20H127.714V23.56H127.954C128.328 22.4667 128.981 21.52 129.914 20.72C130.848 19.92 132.141 19.52 133.794 19.52C135.954 19.52 137.581 20.24 138.674 21.68C139.794 23.12 140.354 25.1733 140.354 27.84V41H134.434V28.32C134.434 26.96 134.221 25.96 133.794 25.32C133.368 24.6533 132.608 24.32 131.514 24.32C131.034 24.32 130.568 24.3867 130.114 24.52C129.661 24.6267 129.248 24.8134 128.874 25.08C128.528 25.3467 128.248 25.68 128.034 26.08C127.821 26.4534 127.714 26.8933 127.714 27.4V41H121.794Z"
        fill={color}
      />
      <path
        d="M151.383 41C149.329 41 147.769 40.48 146.703 39.44C145.663 38.4 145.143 36.88 145.143 34.88V24.6H142.183V20H143.663C144.463 20 145.009 19.8133 145.303 19.44C145.596 19.04 145.743 18.48 145.743 17.76V14.32H151.063V20H155.223V24.6H151.063V36.4H154.903V41H151.383Z"
        fill={color}
      />
      <path
        d="M173.255 41C172.188 41 171.295 40.6667 170.575 40C169.882 39.3334 169.442 38.44 169.255 37.32H169.015C168.695 38.68 167.988 39.72 166.895 40.44C165.802 41.1334 164.455 41.48 162.855 41.48C160.748 41.48 159.135 40.92 158.015 39.8C156.895 38.68 156.335 37.2 156.335 35.36C156.335 33.1467 157.135 31.5067 158.735 30.44C160.362 29.3467 162.562 28.8 165.335 28.8H168.655V27.48C168.655 26.4667 168.388 25.6667 167.855 25.08C167.322 24.4667 166.428 24.16 165.175 24.16C164.002 24.16 163.068 24.4134 162.375 24.92C161.682 25.4267 161.108 26 160.655 26.64L157.135 23.52C157.988 22.2667 159.055 21.2934 160.335 20.6C161.642 19.88 163.402 19.52 165.615 19.52C168.602 19.52 170.842 20.1734 172.335 21.48C173.828 22.7867 174.575 24.6933 174.575 27.2V36.4H176.535V41H173.255ZM165.055 37.36C166.042 37.36 166.882 37.1467 167.575 36.72C168.295 36.2934 168.655 35.6 168.655 34.64V32.16H165.775C163.455 32.16 162.295 32.9467 162.295 34.52V35.12C162.295 35.8934 162.535 36.4667 163.015 36.84C163.495 37.1867 164.175 37.36 165.055 37.36Z"
        fill={color}
      />
      <path
        d="M178.909 41V20H184.829V23.56H185.069C185.442 22.4667 186.095 21.52 187.029 20.72C187.962 19.92 189.255 19.52 190.909 19.52C193.069 19.52 194.695 20.24 195.789 21.68C196.909 23.12 197.469 25.1733 197.469 27.84V41H191.549V28.32C191.549 26.96 191.335 25.96 190.909 25.32C190.482 24.6533 189.722 24.32 188.629 24.32C188.149 24.32 187.682 24.3867 187.229 24.52C186.775 24.6267 186.362 24.8134 185.989 25.08C185.642 25.3467 185.362 25.68 185.149 26.08C184.935 26.4534 184.829 26.8933 184.829 27.4V41H178.909Z"
        fill={color}
      />
    </svg>
  );
};

const KantanLogo = {
  Small,
  Full,
};

export default KantanLogo;
