import {
  addAlert,
  Alert,
  removeAlert,
  useAlertStore,
} from 'src/components/Alert';

type alertProps = Omit<Alert, 'id'> & {
  showOverlay?: boolean;
  showAfter?: number;
  timeout?: number;
};

export const useAlert = () => {
  const { dispatch } = useAlertStore();

  function alert({
    type,
    message,
    callToAction,
    showAfter = 0,
    timeout = 3000,
    canDismiss = true,
    showOverlay = false,
  }: alertProps) {
    const id = Math.random().toString(36);
    const alert = {
      type,
      id,
      message,
      callToAction,
      canDismiss,
      showOverlay,
    };
    // Add alert after showAfter delay
    setTimeout(() => {
      dispatch(addAlert(alert, showOverlay));
    }, showAfter);

    // Remove alert after showAfter delay and lifespan
    // Only remove alert if it can be dismissed and
    // there is no overlay (alerts with overlays are removed
    // by clicking on the overlay)
    if (timeout && canDismiss && !showOverlay) {
      setTimeout(() => {
        dispatch(removeAlert(id));
      }, showAfter + timeout);
    }
  }

  return alert;
};
